import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';

import DefaultLayout from '../components/layout';
import SEO from '../components/seo';
import config from '../../data/SiteConfig';
// eslint-disable-next-line react/prop-types
const CategoriesPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  const categoriesLabel = {
      tech: "Technology",
      products: "Products",
      entertainment: "Entertainment"
  };
  const mapping = {};

  allMarkdownRemark.edges.forEach(({ node }) => {
    const { category } = node.frontmatter;
    if (mapping[category]) {
        mapping[category] += 1;
    } else {
        mapping[category] = 1;
    }
  });

  const categories = Array.from(Object.keys(mapping)).sort(
    (b, a) => mapping[a] - mapping[b],
  );

  return (
    <DefaultLayout>
        <SEO title="Categories" description={config.siteTitle} keywords={[`gobinda`, `categories`, `blogs`, `thakur`]} />
        <div className="pages">
            <h3>Categories</h3>
            <ul>
                {categories.map(cat => (
                    <li key={cat}>
                        <Link to={`/categories/${_.kebabCase(cat)}`}>
                            <span>{categoriesLabel[cat]}</span>
                            <span>{` (${mapping[cat]})`}</span>
                        </Link>
                    </li>
                ))}
            </ul>
      </div>
    </DefaultLayout>
  );
};

export default CategoriesPage;

export const pageQuery = graphql`
  query getAllCategories {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            category
          }
        }
      }
    }
  }
`;